class LocalizationEn
{
    ///// Home  Page /////
    layout_landing_label_welcome = "Welcome to the Registry of";
    layout_landing_label_eventname = "Expo Mueble Internacional Summer edition";
    layout_landing_label_envenname_year = "2025";
    layout_landing_label_recomendation_message = "Please choose your profile carefully."
    layout_landing_button_register =  "Register for the first time";
    layout_landing_button_login =  "Log in";
    layout_landing_label_new_register =  "NEW REGISTRATION";
    layout_landing_label_recurrent =  "RECURRING REGISTRATION";
    layout_landing_label_new_details = "If this is your first time visiting Expo Mueble Internacional and you have not registered online, click here.";
    layout_landing_label_recurrent_details = "If you have already visited Expo Mueble Internacional and generated your registration online, click on “sign in” and update your data to obtain your entry code for the event.";
    layout_landing_label_new_register =  "New Registration";
    layout_landing_label_recurrent =  "Recurring Registration";
    layout_landing_label_register_here =  "Register here";

    
    ///// Login  Page /////
    layout_login_label_backtohome = "Back to Home";
    layout_login_label_login =  "Login with email or by Social Media";
    layout_login_label_noaccount = "Don't have an account?"
    layout_login_link_createaccount = "Register";
    layout_login_link_forgotpassword = "Forgot your password?";
    layout_login_label_email = "Email";
    layout_login_label_password = "Password";
    layout_login_button_login = "Log in";
    layout_login_or = "or";


    /// Register Page ////
    auth_register_label_backtohome = "Back to Home";
    auth_register_label_register = "Sign up";
    auth_register_label_alreadyAccount = "Already have an account? ";
    auth_register_label_login = "Log in";
    auth_register_label_name = "Name";
    auth_register_label_email = "Email";
    auth_register_label_emailconfirmation = "Confirm Email";
    auth_register_label_password = "Password";
    auth_register_label_passwordconfirmation = "Confirm Password ";
    auth_register_button_register = "Continue";

    /// Account Activation ////
    auth_activation_label_hello = "Hello";    
    auth_activation_label_message_success = " your account was activated successfully ";
    auth_activation_label_message_failure = ", your account was not activated successfully, try later please";
    auth_activation_label_button_login = "Continue my registration";

    /// Email Activation  Message ////
    auth_emailactivation_label_confirmation = "YOUR REGISTRATION WAS SUCCESSFUL";
    auth_emailactivation_label_thanks = "Thanks for registering to Expo Mueble Internacional";
    auth_emailactivation_label_email_sent = "A confirmation message was sent to your email, please check your inbox and follow the steps described there to complete your registration.";    
    auth_emailactivation_label_email_not_received = "You did not receive the verification email from Expo Mueble Internacional? Check the spam folder or click on the following link.";
    auth_emailactivation_button_resend = "Resend";
    auth_emailactivation_button_login = "Log in";

    /// Student Register ////
    auth_student_label_formtitle = "Student Registration";    
    auth_student_label_nombre = "*Name";
    auth_student_label_last_name ="*Last Name";
    auth_student_label_email = "*Email";
    auth_student_label_phone = "*Phone";
    auth_student_label_university = "*University";
    auth_student_label_career = "*Career";
    auth_student_button_register = "Register";
    auth_student_label = "Student";

    /// Landing Registry Success ////
    auth_landingsuccess_label_app_name = "EXMI";
    auth_landingsuccess_label_hello = "Hello ";
    auth_landingsuccess_label_message_success = " your registration was successfully completed ";
    auth_landingsuccess_label_message_sent = "A confirmation email was sent to ";
    auth_landingsuccess_label_message_note = "To enter the event, it is necessary to present that email in register (digital) along with an official identification to obtain your badge."
    auth_landing_back_button = "Return to Expo Mueble Internacional";


    /// Password Reset ////
    auth_passwordreset_label_backtohome = "Back to Home";
    auth_passwordreset_label_formtitle = "Restore password";
    auth_passwordreset_label_usuario = "User";
    auth_passwordreset_label_email = "Email";
    auth_passwordreset_label_password = "Password";    
    auth_passwordreset_label_passwordconfirmation = "Confirm Password";  
    auth_passwordreset_button_confirmation = "Accept";     
    
    /// Password Reset Email Sent ////
    auth_passwordresetemail_label_backtohome = "Back to Home";
    auth_passwordresetemail_label_formtitle = "Restore password";
    auth_passwordresetemail_label_message = "To reset your password, enter your email address and press the button below. Follow the instructions included in the email you will receive.";
    auth_passwordresetemail_label_email = "Email";    
    auth_passwordreset_button_confirmation = "Send email";      

    /// Visitante ///
    auth_visitante_label_formtitle = "Visitor Registration";
    auth_visitante_label_name = "*Name";
    auth_visitante_label_lastname ="*Last Name";
    auth_visitante_label_email ="*Email";
    auth_visitante_label_companyname = "*Company Name";    
    auth_visitante_label_career = "*Career";
    auth_visitante_button_register = "Register";    
    auth_visitante_label = "Visitor";

    /// Comprador Landing Success ///
    dashboard_compradorlanding_label_hello = "Hello ";
    dashboard_compradorlanding_label_message_success = " your registration was successfully completed ";
    dashboard_compradorlanding_label_message_sent = "A confirmation email was sent to ";
    dashboard_compradorlanding_label_spam = " Do not forget to check your spam folder.";
    dashboard_compradorlanding_label_message_note = "To enter the event, it is necessary to present that email in register (printed or digital) along with an official identification to obtain your badge."
    dashboard_compradorlanding_label_update_success = " Your data was updated successfully"; 
    dashboard_compradorlanding_button_home = "Home";
    dashboard_compradorlanding_toast_success = "Email sent";
    dashboard_compradorlanding_toast_failure = "Email was not sent, try again later please";

    /// Home ////
    dashboard_dashboard_label_hello = "Hello ";
    dashboard_dashboard_label_message_success = "You have successfully logged in";
    dashboard_dashboard_label_message_registryconfirmation = "You are already registered in Expo Mueble Internacional";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer = "You are already registered in Expo Mueble Internacional as";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer_0 = "Buyer";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer_1 = "if you can to change your registration as";
    dashboard_dashboard_label_message_registryconfirmation_non_buyer_2 = "do click on button";

    dashboard_dashboard_button_updatedataregister = "Modify Registration";
    dashboard_dashboard_button_resendinvitation = "Resend invitation";
    dashboard_dashboard_button_qr = "Get my QR code";
    dashboard_dashboard_button_logout = "Log out";
    dashboard_dashboard_button_register = "Continue my registration";
    dashboard_dashboard_message = "Please review and update your information if necessary.";
    dashboard_dashboard_reenviar_invitacion = "In case you do not find the mail with your registration (QR code)."
    dashboard_dashboard_modificar_informacion = "Please review and update your information if necessary.";
    

    ///// Comprador  Page /////
    dashboard_comprador_label_backtohome =  "Back to Home";
    dashboard_comprador_label_personalinformation = "Personal Information";
    dashboard_comprador_label_personalinformation_minimal = "Personal Information";
    dashboard_comprador_label_yearofbirth = "*Year of Birth (ie. 1982)";
    dashboard_comprador_label_sex = "*Gender";
    dashboard_comprador_label_female = "Female";
    dashboard_comprador_label_gender_other = "Other";
    dashboard_comprador_label_gender_not_specified = "I prefer not to indicate";    
    dashboard_comprador_label_male = "Male";
    dashboard_comprador_label_name = "*First Name";
    dashboard_comprador_label_lastname = "*Last Name";
    dashboard_comprador_label_mail = "*Email";
    dashboard_comprador_label_cellphone = "*Mobile";
    dashboard_comprador_label_whatsapp = "WhatsApp";
    dashboard_comprador_label_country_code = "Country Code";
    dashboard_comprador_label_phone = "Phone";
    dashboard_comprador_label_adress = "*Adress";
    dashboard_comprador_label_Street = "Full Adress";
    dashboard_comprador_label_StreetNumber = "Number";
    dashboard_comprador_label_neighbordhood = "Neighborhood / County";
    dashboard_comprador_label_city = "*City";
    dashboard_comprador_label_zipcode = "*Zip Code";
    dashboard_comprador_label_state = "*State";
    dashboard_comprador_label_state_select = "Select State...";
    dashboard_comprador_label_country = "*Country";
    dashboard_comprador_label_country_select = "Select Country...";
    dashboard_comprador_label_city_select = "Select City...";
    dashboard_comprador_label_companyinformation = "Information about your company";
    dashboard_comprador_label_companyinformation_minimal = "Company information";
    dashboard_comprador_label_nameofcompany = "*Company Name";
    dashboard_comprador_label_nameofcompany_member = "*Company you belong to";
    dashboard_comprador_label_businessname = "Business Name";
    dashboard_comprador_label_companyposition = "*Position within the Company";
    dashboard_comprador_label_companyemail = "*Company Email";
    dashboard_comprador_label_companywebpage = "Company website";
    dashboard_comprador_label_socialnetworkurl = "Social media fan page";

    dashboard_comprador_label_business = "*What's your main business?";
    dashboard_comprador_label_retailer = "Retailer";
    dashboard_comprador_label_furniture = "Furnishing store (online and in-store)";
    dashboard_comprador_label_deparmentalstore = "Deparment store (online and in-store)";
    dashboard_comprador_label_decorationstore = "Decoration Store";
    dashboard_comprador_label_wholesale = "Wholesale";
    dashboard_comprador_label_broker = "Broker";
    dashboard_comprador_label_distributor = "Distributor";    
    dashboard_comprador_label_construction_hospitality = "Hotel / Restaurant / Coffee shop";
    dashboard_comprador_label_manufacturer = "Manufacturer";

    dashboard_comprador_label_exporter ="Exporter";
    dashboard_comprador_label_importer ="Importer";
    dashboard_comprador_label_construction = "Construction"
    dashboard_comprador_label_restaurantbar = "Restaurant / Bar";
    dashboard_comprador_label_hotel = "Hospitality, Restaurants, Cafes";    
    dashboard_comprador_label_decorationServices = "Professional services: Interior Design, Architectural or similar";
    dashboard_comprador_label_type_business = "*Type of Business";
    dashboard_comprador_label_construction_real_state = "Real State / Construction";

    dashboard_comprador_label_interest = "*Products you are interested in";
    dashboard_comprador_label_accesories = "Decoration";
    dashboard_comprador_label_religiousart  = "Sacred art / wood sculptures";        
    dashboard_comprador_label_entertaimentcenters = "Entertainment Centers";
    dashboard_comprador_label_kitchen = "Kitchen";
    dashboard_comprador_label_mattresses = "Mattresses";
    dashboard_comprador_label_dinning = "Dinning Rooms";
    dashboard_comprador_label_lighting = "Lighting";
    dashboard_comprador_label_rocking_chairs = "Rocking chairs";
    dashboard_comprador_label_side_tables = "Coffee and side tables";
    dashboard_comprador_label_wooden_bunk_beds = "Wooden bunk beds";    
    dashboard_comprador_label_rugs = "Rugs";       
    dashboard_comprador_label_children = "Children's and youth furnishing";
    dashboard_comprador_label_office = "Office and business";
    dashboard_comprador_label_bedroom = "Bedrooms";
    dashboard_comprador_label_services = "Services";
    dashboard_comprador_label_upholstery = "Upholstered Furniture Living Room and Sofas";
    dashboard_comprador_label_outdoor = "Casual and garden";
    dashboard_comprador_label_textil = "Textile, Leader and Vinyl";
    dashboard_comprador_label_others = "Others";
    dashboard_comprador_label_event = "*¿How?";
    dashboard_comprador_label_findoutemail= "Email";
    dashboard_comprador_label_findoutmail= "Traditional Mail";
    dashboard_comprador_label_findoutradio= "Radio";
    dashboard_comprador_label_findoutnewspaper= "Newspaper";
    dashboard_comprador_label_findoutmagazine= "Printed Magazine";
    dashboard_comprador_label_findoutmagazine_digital= "Digital Magazine";
    dashboard_comprador_label_findoutbillboard= "BillBoard";
    dashboard_comprador_label_findoutwebsite= "Web Site";
    dashboard_comprador_label_findoutotherwebsite= "Others Web Sites";
    dashboard_comprador_label_findoutsocialnetworks= "Social Networks";
    dashboard_comprador_label_findoutrecomendation= "Recommendation (known person)";
    dashboard_comprador_label_findoutrecomendation_supplier= "Recomendacion (supplier invitation)";
    dashboard_comprador_label_findoutother= "Other";
    dashboard_comprador_label_mainactivity = "*Primary Activity";
    dashboard_comprador_label_mainactivity_directSales = "Retail";
    dashboard_comprador_label_mainactivity_wholesale = "Wholesale";
    dashboard_comprador_label_mainactivity_importer = "Importer";
    dashboard_comprador_label_mainactivity_exporter = "Exporter";
    dashboard_comprador_label_mainactivity_construction = "Construction";
    dashboard_comprador_label_mainactivity_services = "Interior Design or Architectural Services";

    
    dashboard_comprador_label_target = "*Target";
    dashboard_comprador_label_target_corporate = "Corporate and Business";
    dashboard_comprador_label_target_hotels ="Hospitality";
    dashboard_comprador_label_target_residential = "Residential";
    dashboard_comprador_label_target_construction = "Construction";
    dashboard_comprador_label_target_otros ="Others";

    dashboard_comprador_label_attendance = "*Reason for attending";
    dashboard_comprador_label_attendance_trends = "Know the trends";
    dashboard_comprador_label_attendance_suppliers_visit = "Visit to suppliers";
    dashboard_comprador_label_attendance_meet_new_suppliers = "Meet new suppliers";
    dashboard_comprador_label_attendance_conferences = "Conference Program";
    dashboard_comprador_label_attendance_meet_design_finalists = "Meet finalists of the design contest";
    dashboard_comprador_label_attendance_otros = "Others";
    dashboard_comprador_label_visit = "*Reason to visit";
    dashboard_comprador_label_visit_occupation = "*Occupation";

    dashboard_comprador_label_publicity = "Have you had contact with advertising from Expo Mueble Internacional?";
    dashboard_comprador_label_publicity_yes = "Yes";
    dashboard_comprador_label_publicity_no = "No";

    dashboard_comprador_label_interest_title = "Of all the products at Expo Mueble Internacional, which ones are of your main interest?";
    dashboard_comprador_label_interest = "*Target";
    dashboard_comprador_label_interest_corporate = "Business and Commercial";
    dashboard_comprador_label_interest_hotels ="Hotels, Restaurant and CoffeShop";
    dashboard_comprador_label_interest_residential ="Home Furnishing";
    dashboard_comprador_label_interest_construction ="Construction";
    dashboard_comprador_label_interest_otros ="Others";
    dashboard_comprador_button_registro ="Register";
    dashboard_comprador_button_update ="Update";
    dashboard_comprador_label_advice =  "*By submitting your registration you are accepting our Terms and Conditions as set forth in our ";
    dashboard_comprador_label_privacy = "Privacy Notice."
    dashboard_comprador_label_privacy_url = "https://expomuebleinternacional.com.mx/aviso-de-privacidad";

    button_wizard_continue = "CONTINUE >>";
    button_wizard_back = "<< GO BACK";
    button_wizard_finish = "SUBMIT";

    button_submission_error = "There as an technical issue processing your request, please try later, support contact [expos@apptl.info | +52 33 33433400]";
    support_message = "*For technical issues please contact our support team through the email ";
    support_email="expos@apptl.info"

    dashboard_comprador_label_pre_market = "¿Would you like to attend the PreMarket?";
    dashboard_comprador_label_pre_market_minimal = "¿PreMarket Buyer?";
    dashboard_comprador_label_pre_market_desc_minimal = "Confirm if Premarket buyer";
    dashboard_comprador_label_pre_market_desc = "The Premaket is an EXCLUSIVE EVENT FOR LARGE VOLUME BUYERS AND WHOLESALERS, it will take place on Tuesday, February 15, from 10AM to 6PM, at Expo Guadalajara.";

    dashboard_comprador_label_pre_market_landing = `According to the regulations of the event, your request is being validated.
    In case of being approved you will receive a notification with your access to the mail
                                                    `;
    dashboard_comprador_label_pre_market_landing_2 = "in a period of no more than 48 hours.";
    dashboard_comprador_label_pre_market_landing_3 = "Remember that if you do not receive a confirmation from Premarket, you can still visit Expo Mueble Internacional from February 16 to 19.";
    dashboard_comprador_label_pre_market_landing_5 = "Do you have any questions about your Premarket registration?";
    dashboard_comprador_label_pre_market_landing_6 = "Write us to the mail: ";    

    dashboard_comprador_label_pre_market_confirm_button = "Confirm";
    dashboard_comprador_label_pre_market_vipprocessed = "Buyer confirmed";
    premarket_message = "* When confirming the type of buyer, the corresponding access code will be sent";
    button_submission_premaket_success = "The buyer's category was satisfactorily completed! invite was sent";

    premarket_error_buyer_not_found = "It was not possible to get the buyer's information, please try again later, support contact [expos@apptl.info | +52 33 33433400]";

    event_rules_title ="EVENT RULES";
    event_rules_message_0 = "Registration is personal,"
    event_rules_message_1 = "therefore, a record must be made for each person attending."
    event_rules_message_2 = "Please choose your profile carefully."
    event_rules_message_3 = "we appreciate your registration as a “visitor”."
    event_rules_message_4 = "To get your badge";
    event_rules_message_5 = "you have to show your qr code, official identification and a business card (preferably digital)."
    event_rules_message_6 = "Those under 18 years of age are not allowed to enter the event"
    event_rules_message_7 = "You cannot enter with strollers."
    event_rules_message_8 = "We do not have a childcare service."
    event_rules_message_9 = "Pets are not allowed except guide dogs."
    event_rules_message_10 = "The organizing committee reserves the right to modify and update the information provided, depending on its veracity."
    event_ackowledge = "I have read and accept the full privacy notice described ";
    event_button_continue = "Continue";

    soporte_FAQ = "FREQUENT QUESTIONS";
    soporte_desc_0 ="For technical assistance with registration, click";
    soporte_desc_1 ="for technical support.";
    soporte_desc_decarga_app_0 ="Download our app";
    soporte_desc_descarga_app_1 ="here ";
    soporte_chat ="SUPPORT";
    soporte_url_android = "https://play.google.com/store/apps/details?id=com.kingconf.expomueble&hi=en_US&gl=US";
    soporte_url_ios ="https://apps.apple.com/fr/app/expo-mueble-intl/id1078878557";
    space = " ";

    FAQ_question_1 = "Who can enter the international furniture expo?";
    FAQ_answer_1 = "The event is mainly focused on wholesale or project buyers (furniture stores, marketplaces, distributors, interior designers, architects, among others). Those who seek personal purchases can enter as visitors, taking into account that the exhibitors have the right to reserve the sale.";
    FAQ_question_2 = "Can I enter if I am not part of the furniture industry?";
    FAQ_answer_2 = "Yes, it is only necessary to fill out the corresponding registration in the visitor category.";
    FAQ_question_3 = "Can I enter if I am a student?";
    FAQ_answer_3 = "Yes, they just have to fill out their registration in the student category.";
    FAQ_question_4 = "How can I enter?";
    FAQ_answer_4 = "It is necessary to generate a record to obtain your entry badge. This registration can be online or in person. In the case of online registration, this is done on our website: www.expomuebleinternacional.com.mx; In the case of face-to-face registration, this is done during the days of the event in the modules located at the entrances to the venue. We recommend pre-registration online, as it speeds up your entry to the event.";
    FAQ_question_5 = "What is the process to register online?";
    FAQ_answer_5 = "You must enter our website in the registration section and fill out the forms by selecting your appropriate profile. Once you have completed the registration, you should receive an email with the information of your entry to the event (we appreciate checking your inbox as well as the spam box).";
    FAQ_question_6 = "What happens if I do not receive my email with my access to Expo Mueble Internacional?";
    FAQ_answer_6 = "Before contacting support, we appreciate the following:";
    FAQ_answer_6_1 = "1. Check your spam or junk mail box";
    FAQ_answer_6_2 = "2. Confirm that your email has enough space to receive information (that your inbox is not full)";
    FAQ_answer_6_3 = "3. Have verified your account correctly and according to the indications of the registration.";
    FAQ_answer_6_4 = "If you have done all of the above and have ensured that you have not received your email with information to enter the international furniture expo, please contact support by email: ";
    FAQ_answer_6_5 = "expos@apptl.info";
    FAQ_question_7 = "Can I register more than one person with my email (or with a single email address)?";    
    FAQ_answer_7 = "No, registration is personal. You have to use a different email for each person who wants to enter Expo Mueble Internacional.";

    
    //Express registration
    auth_express_label_formtitle = "Express Registration";
    express_comprador_label_nameofcompany_member = "Company you belong to";
    express_comprador_label_type_business = "Type of Business";

    //Error Login
    error_message_social_login = "There was an error with your social login, please try again"
    error_button_login = "Try again";

    // Social networks ling
    social_media_message = "Sign in with Social Media:";
    social_login_facebook= "Log in with Facebook";
    social_login_gmail = "Log in with Google";
    social_login_hotmail ="Log in with Hotmail";

    responsabilidad = "The Jalisco Furniture Manufacturers Association AC, through Expo Mueble Internacional and Tecno Mueble Internacional, is not responsible for purchase and sale transactions and/or quotations made during its events. These are the sole responsibility of visitors, buyers, and exhibitors.";

    visita = "Visit: ";

    buyer_message = "You attend the event to make wholesale purchases (brand representatives, furniture stores, architects, interior designers)";
    visitor_message = "You attend the event to learn about furniture trends"

    event_mesasge_0 = "The leading furniture and decoration event in Latin America. ";
    event_mesasge_1 = "With more than 500 brands and thousands of products on display.";
    event_mesasge_2 = " Join us from ";
    event_mesasge_3 = "19 al 22 de febrero de 2025";
    event_mesasge_4 = ", y aprovecha las mejores oportunidades comerciales del mercado.";

    motivo_asiste_0 = "Professional networking"; 
    motivo_asiste_1 = "Exploring trends"
    motivo_asiste_2 = "Product sourcing";
    motivo_asiste_3 = "Creative inspiration";
    motivo_asiste_4 = "Learning and development";
    motivo_asiste_5 = "Business representation"
    motivo_asiste_6 = "Other";



}

export default LocalizationEn;
