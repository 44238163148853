import React, { useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { registerAttendeeNew } from "../../actions/attendeeActions";
import materialize from "materialize-css"
import Localization from "../localization/localization"
import StepWizard from "react-step-wizard";

import { Email } from "./Email"
import { ExpressInformation } from "./ExpressInformation";
import { getCountryByIsoName } from "../../actions/countriesActions";


const Express = () => {
    const [state, updateState] = useState({
        form: {
            nombres: "",
            apellidos: "",
            email: "",
            ciudad: "",
            estado: "",
            pais: "MX",
            codigoPostal: "",
            nombreEmpresa: "",
            whatsapp: "",
            telefonoCodigoPais:"52",
            giroOtros: "",
            categoria: "VISITANTE",
            tipoRegistro: "pre",
            locale: "es",
            notas: "EXPRESS",
            event:""
        }
    });

    const [disableSubmit, setdisableSubmit] = useState(false);

    const locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
    const history = useHistory();

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });

        if (key === "pais") {
            form["ciudad"] = "";
            updateState({
                ...state,
                form,
            });

            populateCountryPhone(value);

        }
    };

    const submitForm = () => {

        //e.preventDefault();
        setdisableSubmit(true);
        let language = localStorage.getItem("exmi_language");
        if (language == null)
            language = "es";

        let _categoria = getCategoria(state.form.giroOtros)

        const newAttendee = {
            nombres: state.form.nombres,
            apellidos: state.form.apellidos,
            email: state.form.email,

            ciudad: state.form.ciudad,
            pais: state.form.pais,
            estado: state.form.estado,
            codigoPostal: state.form.codigoPostal,

            whatsapp: state.form.whatsapp,
            telefonoCodigoPais: state.form.telefonoCodigoPais,

            nombreEmpresa: state.form.nombreEmpresa,
            giroOtros: state.form.giroOtros,

            categoria: _categoria,
            tipoRegistro: state.form.tipoRegistro,
            notas: "EXPRESS",

            locale: language
        }

        registerAttendeeNew(newAttendee)
            .then(res => history.push("/landing?&id=" + res.data._id+"&guid="+res.data.guid))
            //.then(res => console.log(res))
            .catch(error => {
                setdisableSubmit(false);
                materialize.toast({ html: locale.button_submission_error })
            });
    }


    const getCategoria = (giroEmpresa) => {

        giroEmpresa = giroEmpresa.trim()

        if (giroEmpresa !== '') {
            return 'COMPRADOR'
        }

        return "VISITANTE"
    }

    // Do something on step change
    const onStepChange = (stats) => {
        window.scroll(0, 0);
        materialize.AutoInit();
        materialize.updateTextFields();
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    const populateCountryPhone = (country) => {

        getCountryByIsoName(country)
          .then(response => {
            const { form } = state;
            form.telefonoCodigoPais = response.data[0].phone_code;
            console.log(response.data[0].phone_code);
            updateState({
              ...state,
              form,
            });
          })
          .catch(err => console.log(err));
      }

    return (
        <div className="col s12 m12 l7 container contentaligned overflowDiv">
            <Link to="/" className="btn-flat waves-effect">
                <i className="material-icons left">keyboard_backspace</i> {locale.dashboard_comprador_label_backtohome}
            </Link>
            <h4>
                <b>{locale.auth_express_label_formtitle}</b>
            </h4>
            <StepWizard
                onStepChange={onStepChange}
                //isHashEnabled
                // comment out for default transitions
                instance={setInstance}
            >
                <Email update={updateForm} locale={locale} form={state.form} />
                <ExpressInformation update={updateForm} locale={locale} form={state.form} submit={submitForm} disableSubmit={disableSubmit} />
            </StepWizard>
        </div>
    );
};


export default (withRouter(Express));
