import React, { Component } from "react";
import { Link } from "react-router-dom";
import Localization from "../localization/localization"
//import TiktokPixel from 'tiktok-pixel';

class Landing extends Component {

  constructor() {
    super();
    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));

    ///  Pixel Tiktok
    /*
    const advancedMatching = {
    }; 
    
    const options = {
      debug: false, // enable logs
    };
    */
    //TiktokPixel.init('CI5IPI3C77U441D0TRH0', advancedMatching, options);
    
    //TiktokPixel.pageView(); // For tracking page view
    ////////
  }

  render() {
    return (
      <div class="col s12 m12 l8 container">
          <div class="col s12 m12 l12 center contentaligned">
          <div>
            <div className="font-face-mb titulo">
              <h3>
                {this.locale.layout_landing_label_welcome} <br></br> {this.locale.layout_landing_label_eventname}{" "}
                {this.locale.layout_landing_label_envenname_year}
              </h3>
            </div>
            <div class="subTitulo smallScreenHide">
              <h6>
                <span className="font-face-mb">{this.locale.event_mesasge_0}</span>{this.locale.event_mesasge_1}
                {this.locale.event_mesasge_2}<span className="font-face-mb">{this.locale.event_mesasge_3}</span>{this.locale.event_mesasge_4}
              </h6>
            </div>
            <div className="divSpaces"></div>
            <div class="subTitulo">
              <h6>
              <span className="font-face-mb">
                {this.locale.layout_landing_label_recomendation_message}
                </span>
              </h6>
            </div>
            <div className="divSpaces"></div>
            <div className="landingBackground">     
            <div class="col s12 m12 l6">
              <div className="container">
                <div class="card">
                  <div class="card-header">
                      {this.locale.dashboard_dashboard_label_message_registryconfirmation_non_buyer_0}
                  </div>
                  <div class="card-body">
                      <p>{this.locale.buyer_message}</p>
                  </div>
                  <div class="card-footer">
                      <Link
                to="/prelogin"
                style={{
                  borderRadius: "3px",
                  marginTop: "1rem"
                }}
                className="btn hoverable buttonBackgroundColor no-uppercase"
              >
                {this.locale.layout_landing_label_register_here}
              </Link>
                  </div>
                  </div>
              </div>
            </div>
            <div class="col s12 m12 l6">
              <div className="container">
                    <div class="card">
                    <div class="card-header">
                        {this.locale.auth_visitante_label}
                    </div>
                    <div class="card-body">
                        <p>{this.locale.visitor_message}</p>
                    </div>
                    <div class="card-footer">
                        <Link
                          to="/rules/visitante"
                          style={{
                            borderRadius: "3px",
                            marginTop: "1rem"
                          }}
                          className="btn hoverable buttonBackgroundColor no-uppercase"
                        >
                {this.locale.layout_landing_label_register_here}
              </Link>
                    </div>
                </div>
              </div>
            </div>
            </div>
            </div>
            </div>
          </div>      
            
    );
  }
}

export default Landing;