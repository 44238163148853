
import React, { Component } from "react";
import queryString from 'query-string'
import { Link } from "react-router-dom";
import { userDetails } from "../../actions/authActions";
import { logoutUser } from "../../actions/authActions";
//import { getAttendeeByUserId } from "../../actions/attendeeActions";
import Localization from "../localization/localization";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//import ReactPixel from 'react-facebook-pixel';


class CompradorLanding extends Component {

  constructor() {
    super();

    
    //ReactPixel.init('869801026716151', {}, { debug: false, autoConfig: false });
    //ReactPixel.pageView();
    
    //ReactPixel.fbq('track', 'PageView');
    

    this.state = {
      name: "",
      email: "",
      action: "",
      vip: "false",
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };


  componentDidMount() {
    const params = queryString.parse(this.props.location.search)

    this.setState({ action: params.action });
    /*
    getAttendeeByUserId(params.id)
      .then(res => this.setState({ name: res.data.nombres, email: res.data.email, vip: res.data.vip }))
      .catch(err => {
        console.log(err);
        return null;
      });
    */

    userDetails(params.id)
      .then(res => this.setState({ name: res.data.name, email: res.data.email }))
      .catch(err => {
        console.log(err);
        return null;
      });

  }

  render() {
    return (
      <div>
        <div className="col s12 m12 l8 container overflowDiv">
          <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
            <div>
            {this.state.action === "add" ?
              <>
                {(this.state.vip === "false") ?
                  <>
                    <h4>
                      <p>{this.locale.dashboard_compradorlanding_label_hello} <b>{this.state.name}</b> {this.locale.dashboard_compradorlanding_label_message_success} 👏</p>
                    </h4>
                    <h6>
                      {this.locale.dashboard_compradorlanding_label_message_sent} <b>{this.state.email}</b>
                    </h6>
                    <h6>
                      {this.locale.dashboard_compradorlanding_label_spam}
                    </h6>
                    <br></br>
                    <p>
                      {this.locale.dashboard_compradorlanding_label_message_note}
                    </p>
                  </>
                  :
                  <>
                    <h4>
                      <p>{this.locale.dashboard_compradorlanding_label_hello} <b>{this.state.name}</b> {this.locale.dashboard_compradorlanding_label_message_success_premarket} 👏</p>
                    </h4>
                    <h6>
                      {this.locale.dashboard_comprador_label_pre_market_landing} <b>{this.state.email}</b> {this.locale.dashboard_comprador_label_pre_market_landing_2}
                    </h6>
                    <br></br>
                    <h6>
                      {this.locale.dashboard_comprador_label_pre_market_landing_3}
                    </h6>
                    <br></br>
                    <h6>
                      {this.locale.dashboard_compradorlanding_label_spam}
                    </h6>
                    <br></br>
                    <p>
                      {this.locale.dashboard_comprador_label_pre_market_landing_5}
                    </p>
                    <p>
                      {this.locale.dashboard_comprador_label_pre_market_landing_6}<b>isabel.padilla@afamjal.com.mx</b>
                    </p>
                    <br></br>
                    <p>
                      {this.locale.dashboard_compradorlanding_label_message_note}
                    </p>
                  </>
                }

              </>
              :
              <>
                {(this.state.vip === "true") ?
                  <>
                    <h4>
                      <p>{this.locale.dashboard_compradorlanding_label_hello} <b>{this.state.name}</b> {this.locale.dashboard_compradorlanding_label_message_success_premarket} 👏</p>
                    </h4>
                    <h6>
                      {this.locale.dashboard_comprador_label_pre_market_landing} <b>{this.state.email}</b> {this.locale.dashboard_comprador_label_pre_market_landing_2}
                    </h6>
                    <br></br>
                    <h6>
                      {this.locale.dashboard_comprador_label_pre_market_landing_3}
                    </h6>
                    <br></br>
                    <h6>
                      {this.locale.dashboard_compradorlanding_label_spam}
                    </h6>
                    <br></br>
                    <p>
                      {this.locale.dashboard_comprador_label_pre_market_landing_5}
                    </p>
                    <p>
                      {this.locale.dashboard_comprador_label_pre_market_landing_6}<b>isabel.padilla@afamjal.com.mx</b>
                    </p>
                  </>
                  :
                  <>
                    <h4>
                      <p>{this.locale.dashboard_compradorlanding_label_hello} <b>{this.state.name}</b> {this.locale.dashboard_compradorlanding_label_update_success} 👏</p>
                    </h4>
                  </>
                }
              </>
            }
            <br /><br />
            <button
              style={{
                width: "220px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem"
              }}
              onClick={this.onLogoutClick}
              className="btn-large hoverable buttonBackgroundColor font-face-msb"
            >
              {this.locale.dashboard_dashboard_button_logout}
            </button>
            <br/><br/><br/><br/>
            <Link
              to="/Dashboard"
              style={{
                width: "200px",
                borderRadius: "3px",
                letterSpacing: "1.5px"
              }}
              className="btn hoverable buttonSoporteBackgroundColor"
            >
              {this.locale.dashboard_compradorlanding_button_home}
            </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//export default CompradorLanding;

CompradorLanding.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(CompradorLanding));
